/**
 * Trumbowyg v2.21.0 - A lightweight WYSIWYG editor
 * Default stylesheet for Trumbowyg editor
 * ------------------------
 * @link http://alex-d.github.io/Trumbowyg
 * @license MIT
 * @author Alexandre Demode (Alex-D)
 *         Twitter : @AlexandreDemode
 *         Website : alex-d.fr
 */

#smeditor-icons {
    overflow: hidden;
    visibility: hidden;
    height: 0;
    width: 0;
}

#smeditor-icons svg {
    height: 0;
    width: 0;
}

.smeditor-box *,
.smeditor-box *::before,
.smeditor-box *::after,
.smeditor-modal *,
.smeditor-modal *::before,
.smeditor-modal *::after {
    box-sizing: border-box;
}

.smeditor-box svg,
.smeditor-modal svg {
    width: 17px;
    height: 100%;
    fill: #302f35;
}


.smeditor-box button:hover svg,
.smeditor-modal button:hover svg {
    fill: #ffffff;
}

.smeditor-box,
.smeditor-editor {
    display: block;
    position: relative;
    width: 100%;
    min-height: 300px;
    margin: 0;
    clear: both;
    float: left;
    border: 1px solid rgba(48, 47, 53, 0.5);
    border-radius: 8px;
}

.smeditor-box {
    overflow: hidden;
}

.smeditor-box .smeditor-editor {
    margin: 0 auto;
}

.smeditor-box.smeditor-fullscreen {
    background: #FEFEFE;
    border: none !important;
}

.smeditor-editor,
.smeditor-textarea {
    position: relative;
    box-sizing: border-box;
    padding: 20px;
    min-height: 300px;
    width: 100%;
    border-style: none;
    resize: none;
    outline: none;
    overflow: auto;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.smeditor-editor.smeditor-autogrow-on-enter,
.smeditor-textarea.smeditor-autogrow-on-enter {
    -webkit-transition: height 300ms ease-out;
    transition: height 300ms ease-out;
}

.smeditor-box-blur .smeditor-editor *,
.smeditor-box-blur .smeditor-editor::before {
    color: transparent !important;
    text-shadow: 0 0 7px #333;
}

@media screen and (min-width: 0 \0 ) {
    .smeditor-box-blur .smeditor-editor *,
    .smeditor-box-blur .smeditor-editor::before {
        color: rgba(200, 200, 200, 0.6) !important;
    }
}

@supports (-ms-accelerator: true) {
    .smeditor-box-blur .smeditor-editor *,
    .smeditor-box-blur .smeditor-editor::before {
        color: rgba(200, 200, 200, 0.6) !important;
    }
}

.smeditor-box-blur .smeditor-editor img,
.smeditor-box-blur .smeditor-editor hr {
    opacity: 0.2;
}

.smeditor-textarea {
    position: relative;
    display: block;
    overflow: auto;
    border: none;
    font-size: 14px;
    font-family: "Inconsolata", "Consolas", "Courier", "Courier New", sans-serif;
    line-height: 18px;
}

.smeditor-box.smeditor-editor-visible .smeditor-textarea {
    height: 1px !important;
    width: 25%;
    min-height: 0 !important;
    padding: 0 !important;
    background: none;
    opacity: 0 !important;
}

.smeditor-box.smeditor-editor-hidden .smeditor-textarea {
    display: block;
    margin-bottom: 1px;
}

.smeditor-box.smeditor-editor-hidden .smeditor-editor {
    display: none;
}

.smeditor-box.smeditor-disabled .smeditor-textarea {
    opacity: 0.8;
    background: none;
}

.smeditor-editor[contenteditable=true]:empty:not(:focus)::before {
    content: attr(placeholder);
    color: #999;
    pointer-events: none;
}

.smeditor-button-pane {
    width: 100%;
    min-height: 36px;
    background: #979699;
    border-bottom: 1px solid #979699;
    margin: 0;
    padding: 5px;
    padding-bottom:1px;
    position: relative;
    list-style-type: none;
    line-height: 10px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 11;
}

.smeditor-button-pane::after {
    content: " ";
    display: block;
    position: absolute;
    top: 36px;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: #d7e0e2;
    opacity: 0;
}

.smeditor-button-pane .smeditor-button-group {
    display: inline-block;
}

.smeditor-button-pane .smeditor-button-group .smeditor-fullscreen-button svg {
    color: transparent;
}

.smeditor-button-pane .smeditor-button-group::after {
    content: " ";
    display: inline-block;
    width: 1px;
    background: #d7e0e2;
    margin: 0 5px;
    height: 35px;
    vertical-align: top;
    opacity: 0;
    margin: 0px;
    width: 0px;
}

.smeditor-button-pane .smeditor-button-group:last-child::after {
    content: none;
}

.smeditor-button-pane button {
    display: inline-block;
    position: relative;
    min-width: 40px;
    height: 40px;
    padding: 1px 6px !important;
    margin-bottom: 5px;
    border-radius: 8px;
    overflow: hidden;
    border: none;
    cursor: pointer;
    background: none;
    vertical-align: middle;
    -webkit-transition: background-color 150ms, opacity 150ms;
    transition: background-color 150ms, opacity 150ms;
    background: #ffffff;
    margin-right: 3px;
}

.smeditor-button-pane button.smeditor-open-dropdown {
    padding-right: 28px !important;
    padding-left: 12px !important;
}

.smeditor-button-pane button:hover {
    background: #302f35;

}

.smeditor-button-pane button .smeditor-button-pane button.smeditor-textual-button {
    width: auto;
    line-height: 35px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.smeditor-button-pane.smeditor-disable button:not(.smeditor-not-disable):not(.smeditor-active),
.smeditor-button-pane button.smeditor-disable,
.smeditor-disabled .smeditor-button-pane button:not(.smeditor-not-disable):not(.smeditor-viewHTML-button) {
    opacity: 0.2;
    cursor: default;
}

.smeditor-button-pane.smeditor-disable .smeditor-button-group::before,
.smeditor-disabled .smeditor-button-pane .smeditor-button-group::before {
    background: #e3e9eb;
}

.smeditor-button-pane button:not(.smeditor-disable):hover,
.smeditor-button-pane button:not(.smeditor-disable):focus,
.smeditor-button-pane button.smeditor-active {
    background: #302f35;
    outline: none;
    color:#ffffff;
}

.smeditor-button-pane button:not(.smeditor-disable):hover svg,
.smeditor-button-pane button:not(.smeditor-disable):focus svg,
.smeditor-button-pane button.smeditor-active svg {
    fill:#ffffff;
}


.smeditor-button-pane .smeditor-open-dropdown::after {
    display: block;
    content: " ";
    position: absolute;
    top: 16px;
    right: 18px;
    height: 0;
    width: 0;
    font-family: "Font Awesome 5 Pro";
    content: "\f107";
    border: none;
    font-size: 12px;
}

.smeditor-button-pane button.smeditor-open-dropdown {
    padding-right: 28px !important;
    padding-left: 12px !important;
}

.smeditor-button-pane .smeditor-open-dropdown.smeditor-textual-button {
    padding-left: 18px !important;
    padding-right: 34px !important;
}

.smeditor-button-pane .smeditor-open-dropdown.smeditor-textual-button::after {
    top: 15px;
    right: 22px;
}

.smeditor-button-pane .smeditor-right {
    float: right;
}

.smeditor-dropdown {
    max-width: 300px;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    border: none;
    padding: 9px 0;
    border-top: none;
    background: #FFF;
    margin-left: -1px;
    z-index: 12;
    box-shadow: 0px 0px 20px 0px rgba(48, 47, 53, 0.42);
    border-radius: 13px;
    margin-top: 10px;
}

.smeditor-dropdown button {
    display: block;
    width: 100%;
    height: 35px;
    line-height: 35px;
    text-decoration: none;
    background: #FFF;
    padding: 0 15px 0 15px;
    color: #302f35 !important;
    border: none;
    cursor: pointer;
    text-align: left;
    font-size: 14px;
    -webkit-transition: all 150ms;
    transition: all 150ms;
}

.smeditor-dropdown button:hover,
.smeditor-dropdown button:focus {
    background: #dedede;
}

.smeditor-dropdown button svg {
    float: left;
    margin-right: 14px;
}

.smeditor-dropdown button:hover svg,
.smeditor-dropdown button:focus svg {
    fill: #302f35 !important;
}

/* Modal box */

.smeditor-modal {
    position: fixed;
    top: 50% !important;
    left: 50%;
    -webkit-transform: translateX(-50%)translateY(-50%);
    transform: translateX(-50%)translateY(-50%);
    max-width: 520px;
    width: 100%;
    height: 350px;
    z-index: 12;
    overflow: visible;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    height: auto !important;
    float: left;
    display: block;
}

.smeditor-modal-box {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    max-width: 500px;
    width: calc(100% - 20px);
    padding-bottom: 45px;
    z-index: 1;
    background-color: #FFF;
    text-align: center;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.smeditor-modal-box {
    box-shadow: 0px 0px 19px 0px rgba(48, 47, 53, 0.15);
    border-radius: 20px;
    border: none;
    background: #ffffff;
    float: left;
    width: 100%;
    overflow: hidden;
    padding: 0px;
    position: relative;
}

.smeditor-modal-box .smeditor-modal-title {
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 20px;
    padding: 15px 0 13px;
    display: block;
    border-bottom: 1px solid #EEE;
    color: #333;
    background: #fbfcfc;
}

.smeditor-modal-box .smeditor-modal-title {
    float: left;
    display: block;
    color: #302f35;
    font-size: 20px;
    font-family: "Uniform";
    font-weight: 500;
    line-height: 0.8;
    margin: -5px 0 -5px 0;
    padding: 30px 30px 35px;
    width: 100%;
    text-align: left;
    border:none;
}

.smeditor-modal-box .smeditor-progress {
    width: 100%;
    height: 3px;
    position: absolute;
    top: 58px;
}

.smeditor-modal-box .smeditor-progress .smeditor-progress-bar {
    background: #2BC06A;
    width: 0;
    height: 100%;
    -webkit-transition: width 150ms linear;
    transition: width 150ms linear;
}

.smeditor-modal-box label {
    display: block;
    position: relative;
    margin: 0;
    height: auto;
    line-height: normal;
    overflow: visible;
    padding: 0 30px;
    float: left;
    width: 100%;
    margin-bottom: 23px;
    display: flex;
    flex-wrap: wrap;
}
.smeditor-modal-box label .smeditor-input-infos {
    display: block;
    text-align: left;
    height: auto;
    line-height: auto;
    -webkit-transition: all 150ms;
    transition: all 150ms;
    width: 100%;
    float: left;
    order: 1;
}

.smeditor-modal-box label .smeditor-input-infos span {
    display: block;
    padding: 0 7px;
    width: 100%;
    line-height: 1.3;
    color: #302f35;
    font-size: 14px;
    font-family: "Uniform";
    font-weight: 600;
    margin: -3px 0 12px 0;
    cursor: pointer;
    height: auto;
    padding: 0px;
    border: none;
}

.smeditor-modal-box label:hover .smeditor-input-infos span {
    color: #4f29dc;
}

.smeditor-modal-box label .smeditor-input-infos span.smeditor-msg-error {
    color: #e74c3c;
}

.smeditor-modal-box label.smeditor-input-error input,
.smeditor-modal-box label.smeditor-input-error textarea {
    border: 1px solid #e74c3c;
}

.smeditor-modal-box label.smeditor-input-error .smeditor-input-infos {
    margin-top: -27px;
}

.smeditor-modal-box label input {
    font-size: 14px;
    float: left;
    width: 100%;
    display: block;
    background: #ffffff;
    min-height: 46px;
    border-radius: 8px;
    border: 1px solid rgba(48, 47, 53, 0.5);
    box-shadow: 0px 0px 20px 0px rgba(48, 47, 53, 0);
    font-weight: 400;
    color: rgba(48, 47, 53, 0.8);
    padding: 0 20px;
    order: 2;
}

.smeditor-modal-box label input:hover {
    border: 1px solid rgba(48, 47, 53, 0);
    box-shadow: 0px 0px 20px 0px rgba(48, 47, 53, 0.17);
}

.smeditor-modal-box label input:focus {
    border: 1px solid #302f35;
    box-shadow: 0px 0px 0px 0px rgba(48, 47, 53, 0);
    background: #ffffff;
}


.smeditor-modal-box label input[type=checkbox] {
    left: 6px;
    top: 6px;
    right: auto;
    height: 16px;
    width: 16px;
}

.smeditor-modal-box label input[type=checkbox] + .smeditor-input-infos span {
    width: auto;
    padding-left: 25px;
}

.smeditor-modal-box .error {
    margin-top: 25px;
    display: block;
    color: red;
}

.smeditor-modal-box .smeditor-modal-button {
    cursor: pointer;
    transition: all 150ms;
    margin-top: 10px;
    display: block;
    margin-bottom: 30px;
    font-family: "Uniform";
    color: #ffffff;
    font-weight: 600;
    text-decoration: none;
    outline: none !important;
    height: 46px;
    line-height: 1;
    padding: 15px 20px;
    background: rgba(48, 47, 53, 0);
    border: none;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    font-size: 14px;
}

.smeditor-modal-box .smeditor-modal-button.smeditor-modal-submit {
    float: left;
    margin-bottom:30px;
    margin-top:10px;
    margin-left: 30px;
    box-shadow: 0px 0px 18px 0px rgba(27, 96, 11, 0.22);
}

.smeditor-modal-box .smeditor-modal-button.smeditor-modal-reset {
    margin-bottom:30px;
    margin-top:10px;
    float: right;
    margin-right: 30px;
    box-shadow: 0px 0px 18px 0px rgba(74, 6, 6, 0.22);
}

.smeditor-modal-box .smeditor-modal-button.smeditor-modal-submit:before,
.smeditor-modal-box .smeditor-modal-button.smeditor-modal-reset:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    z-index: -1;
    top: 0px;
}

.smeditor-modal-box .smeditor-modal-button.smeditor-modal-submit:after,
.smeditor-modal-box .smeditor-modal-button.smeditor-modal-reset:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    z-index: -1;
    top: 0;
    opacity: 0;
}

.smeditor-modal-box .smeditor-modal-button.smeditor-modal-submit:before {
    background: #2a9e0f;
    background: -webkit-gradient(linear, left top, right top, from(#2a9e0f), to(#38c517));
    background: linear-gradient(to right, #2a9e0f 0%, #38c517 100%);
}

.smeditor-modal-box .smeditor-modal-button.smeditor-modal-reset:before {
    background: #f92222;
    background: -webkit-gradient(linear, left top, right top, from(#f92222), to(#f63737));
    background: linear-gradient(to right, #f92222 0%, #f63737 100%);
}

.smeditor-modal-box .smeditor-modal-button.smeditor-modal-reset:after {
    background: #f63737;
    background: -webkit-gradient(linear, left top, right top, from(#b51515), to(#ec1e1e));
    background: linear-gradient(to right, #b51515 0%, #ec1e1e 100%);
}

.smeditor-modal-box .smeditor-modal-button.smeditor-modal-submit:after {
    background: #38c517;
    background: -webkit-gradient(linear, left top, right top, from(#23840c), to(#2ca90f));
    background: linear-gradient(to right, #23840c 0%, #2ca90f 100%);
}

.smeditor-modal-box .smeditor-modal-button.smeditor-modal-submit:hover:before,
.smeditor-modal-box .smeditor-modal-button.smeditor-modal-reset:hover:before {
}

.smeditor-modal-box .smeditor-modal-button.smeditor-modal-submit:hover:after,
.smeditor-modal-box .smeditor-modal-button.smeditor-modal-reset:hover:after {
    opacity: 1;
}

.smeditor-modal-box .smeditor-modal-button.smeditor-modal-submit:active,
.smeditor-modal-box .smeditor-modal-button.smeditor-modal-submit:focus,
.smeditor-modal-box .smeditor-modal-button.smeditor-modal-reset:active,
.smeditor-modal-box .smeditor-modal-button.smeditor-modal-reset:focus {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
    background: #302f35 !important;
}


.smeditor-overlay {
    position: absolute;
    background-color: rgba(49, 48, 54, 0.3);
    height: 100%;
    width: 100%;
    left: 0;
    display: none;
    top: 0;
    z-index: 10;
}

/**
 * Fullscreen
 */

body.smeditor-body-fullscreen {
    overflow: hidden;
}

.smeditor-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 99999;
}

.smeditor-fullscreen.smeditor-box,
.smeditor-fullscreen .smeditor-editor {
    border: none;
}

.smeditor-fullscreen .smeditor-editor,
.smeditor-fullscreen .smeditor-textarea {
    height: calc(100% - 37px) !important;
    overflow: auto;
}

.smeditor-fullscreen .smeditor-overlay {
    height: 100% !important;
}

.smeditor-fullscreen .smeditor-button-group .smeditor-fullscreen-button svg {
    color: #222;
    fill: transparent;
}

.smeditor-editor {
    font-weight: 400;
    font-size: 15px;
}

.smeditor-editor object,
.smeditor-editor embed,
.smeditor-editor video,
.smeditor-editor img {
    max-width: 100%;
}

.smeditor-editor video,
.smeditor-editor img {
    height: auto;
}

.smeditor-editor img {
    cursor: move;
}

.smeditor-editor canvas:focus {
    outline: none;
}

.smeditor-editor.smeditor-reset-css {
    background: #FEFEFE !important;
    font-family: "Trebuchet MS", Helvetica, Verdana, sans-serif !important;
    font-size: 14px !important;
    line-height: 1.45em !important;
    color: #333;
}

.smeditor-editor.smeditor-reset-css a {
    color: #15c !important;
    text-decoration: underline !important;
}

.smeditor-editor.smeditor-reset-css div,
.smeditor-editor.smeditor-reset-css p,
.smeditor-editor.smeditor-reset-css ul,
.smeditor-editor.smeditor-reset-css ol,
.smeditor-editor.smeditor-reset-css blockquote {
    box-shadow: none !important;
    background: none !important;
    margin: 0 !important;
    margin-bottom: 15px !important;
    line-height: 1.4em !important;
    font-family: "Trebuchet MS", Helvetica, Verdana, sans-serif !important;
    font-size: 14px !important;
    border: none;
}

.smeditor-editor.smeditor-reset-css iframe,
.smeditor-editor.smeditor-reset-css object,
.smeditor-editor.smeditor-reset-css hr {
    margin-bottom: 15px !important;
}

.smeditor-editor.smeditor-reset-css blockquote {
    margin-left: 32px !important;
    font-style: italic !important;
    color: #555;
}

.smeditor-editor.smeditor-reset-css ul {
    list-style: disc;
}

.smeditor-editor.smeditor-reset-css ol {
    list-style: decimal;
}

.smeditor-editor.smeditor-reset-css ul,
.smeditor-editor.smeditor-reset-css ol {
    padding-left: 20px !important;
}

.smeditor-editor.smeditor-reset-css ul ul,
.smeditor-editor.smeditor-reset-css ol ol,
.smeditor-editor.smeditor-reset-css ul ol,
.smeditor-editor.smeditor-reset-css ol ul {
    border: none;
    margin: 2px !important;
    padding: 0 !important;
    padding-left: 24px !important;
}

.smeditor-editor.smeditor-reset-css hr {
    display: block;
    height: 1px;
    border: none;
    border-top: 1px solid #CCC;
}

.smeditor-editor.smeditor-reset-css h1,
.smeditor-editor.smeditor-reset-css h2,
.smeditor-editor.smeditor-reset-css h3,
.smeditor-editor.smeditor-reset-css h4 {
    color: #111;
    background: none;
    margin: 0 !important;
    padding: 0 !important;
    font-weight: bold;
}

.smeditor-editor.smeditor-reset-css h1 {
    font-size: 32px !important;
    line-height: 38px !important;
    margin-bottom: 20px !important;
}

.smeditor-editor.smeditor-reset-css h2 {
    font-size: 26px !important;
    line-height: 34px !important;
    margin-bottom: 15px !important;
}

.smeditor-editor.smeditor-reset-css h3 {
    font-size: 22px !important;
    line-height: 28px !important;
    margin-bottom: 7px !important;
}

.smeditor-editor.smeditor-reset-css h4 {
    font-size: 16px !important;
    line-height: 22px !important;
    margin-bottom: 7px !important;
}

/*
 * Dark theme
 */

.smeditor-dark .smeditor-textarea {
    background: #111;
    color: #ddd;
}

.smeditor-dark .smeditor-box {
    border: 1px solid #343434;
}

.smeditor-dark .smeditor-box.smeditor-fullscreen {
    background: #111;
}

.smeditor-dark .smeditor-box.smeditor-box-blur .smeditor-editor *,
.smeditor-dark .smeditor-box.smeditor-box-blur .smeditor-editor::before {
    text-shadow: 0 0 7px #ccc;
}

@media screen and (min-width: 0 \0 ) {
    .smeditor-dark .smeditor-box.smeditor-box-blur .smeditor-editor *,
    .smeditor-dark .smeditor-box.smeditor-box-blur .smeditor-editor::before {
        color: rgba(20, 20, 20, 0.6) !important;
    }
}

@supports (-ms-accelerator: true) {
    .smeditor-dark .smeditor-box.smeditor-box-blur .smeditor-editor *,
    .smeditor-dark .smeditor-box.smeditor-box-blur .smeditor-editor::before {
        color: rgba(20, 20, 20, 0.6) !important;
    }
}

.smeditor-dark .smeditor-box svg {
    fill: #ecf0f1;
    color: #ecf0f1;
}

.smeditor-dark .smeditor-button-pane {
    background-color: #222;
    border-bottom-color: #343434;
}

.smeditor-dark .smeditor-button-pane::after {
    background: #343434;
}

.smeditor-dark .smeditor-button-pane .smeditor-button-group:not(:empty)::after {
    background-color: #343434;
}

.smeditor-dark .smeditor-button-pane .smeditor-button-group:not(:empty) .smeditor-fullscreen-button svg {
    color: transparent;
}

.smeditor-dark .smeditor-button-pane.smeditor-disable .smeditor-button-group::after {
    background-color: #2a2a2a;
}

.smeditor-dark .smeditor-button-pane button:not(.smeditor-disable):hover,
.smeditor-dark .smeditor-button-pane button:not(.smeditor-disable):focus,
.smeditor-dark .smeditor-button-pane button.smeditor-active {
    background-color: #333;
}

.smeditor-dark .smeditor-button-pane .smeditor-open-dropdown::after {
    border-top-color: #fff;
}

.smeditor-dark .smeditor-fullscreen .smeditor-button-pane .smeditor-button-group:not(:empty) .smeditor-fullscreen-button svg {
    color: #ecf0f1;
    fill: transparent;
}

.smeditor-dark .smeditor-dropdown {
    border-color: #222;
    background: #333;
    box-shadow: rgba(0, 0, 0, 0.3) 0 2px 3px;
}

.smeditor-dark .smeditor-dropdown button {
    background: #333;
    color: #fff !important;
}

.smeditor-dark .smeditor-dropdown button:hover,
.smeditor-dark .smeditor-dropdown button:focus {
    background: #222;
}

.smeditor-dark .smeditor-modal-box {
    background-color: #222;
}

.smeditor-dark .smeditor-modal-box .smeditor-modal-title {
    border-bottom: 1px solid #555;
    color: #fff;
    background: #3c3c3c;
}

.smeditor-dark .smeditor-modal-box label {
    display: block;
    position: relative;
    margin: 15px 12px;
    height: 27px;
    line-height: 27px;
    overflow: hidden;
}

.smeditor-dark .smeditor-modal-box label .smeditor-input-infos span {
    color: #eee;
    background-color: #2f2f2f;
    border-color: #222;
}

.smeditor-dark .smeditor-modal-box label .smeditor-input-infos span.smeditor-msg-error {
    color: #e74c3c;
}

.smeditor-dark .smeditor-modal-box label.smeditor-input-error input,
.smeditor-dark .smeditor-modal-box label.smeditor-input-error textarea {
    border-color: #e74c3c;
}

.smeditor-dark .smeditor-modal-box label input {
    border-color: #222;
    color: #eee;
    background: #333;
}

.smeditor-dark .smeditor-modal-box label input:hover,
.smeditor-dark .smeditor-modal-box label input:focus {
    border-color: #626262;
}

.smeditor-dark .smeditor-modal-box label input:focus {
    background-color: #2f2f2f;
}

.smeditor-dark .smeditor-modal-box .smeditor-modal-button.smeditor-modal-submit {
    background: #1b7943;
}

.smeditor-dark .smeditor-modal-box .smeditor-modal-button.smeditor-modal-submit:hover,
.smeditor-dark .smeditor-modal-box .smeditor-modal-button.smeditor-modal-submit:focus {
    background: #25a25a;
}

.smeditor-dark .smeditor-modal-box .smeditor-modal-button.smeditor-modal-submit:active {
    background: #176437;
}

.smeditor-dark .smeditor-modal-box .smeditor-modal-button.smeditor-modal-reset {
    background: #333;
    color: #ccc;
}

.smeditor-dark .smeditor-modal-box .smeditor-modal-button.smeditor-modal-reset:hover,
.smeditor-dark .smeditor-modal-box .smeditor-modal-button.smeditor-modal-reset:focus {
    background: #444;
}

.smeditor-dark .smeditor-modal-box .smeditor-modal-button.smeditor-modal-reset:active {
    background: #111;
}

.smeditor-dark .smeditor-overlay {
    background-color: rgba(15, 15, 15, 0.6);
}

.aweditor-strong-button {
    content: "yes";
}


