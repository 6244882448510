//Selectize
@import '~selectize/dist/css/selectize.bootstrap3.css';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//Filepond
@import "~filepond/dist/filepond.css";
@import '~filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

//Date range picker
@import '~daterangepicker/daterangepicker.css';

//WYSIWYG
@import "./identify/plugins/trumbowyg/theme";

/* toasts */

.toast-container {
    position: relative;
    min-height: 0px;
}

.toast {
    -webkit-box-shadow: 0px 0px 22px 0px rgb(54 53 53 / 21%);
    -moz-box-shadow: 0px 0px 22px 0px rgb(54 53 53 / 21%);
    box-shadow: 0px 0px 22px 0px rgb(54 53 53 / 21%);
    background: #ffffff;
    border: none;
    border-radius: 0px;
    float: left;
    width: 100%;
    display: block;
    margin-bottom: 15px;
}

.toast .toast-header {
    float: left;
    width: 100%;
    display: block;
    padding: 13px 20px 10px;
    position: relative;
    padding-right: 50px;
    border: none;
}

.toast .toast-header button {
    position: absolute;
    color: red;
    opacity: 1;
    font-size: 24px;
    margin: 0px !important;
    width: 30px;
    height: 30px;
    text-align: center;
    right: 10px;
    top: 8px;
}

.toast .toast-header button:hover {
    opacity:0.6;
}

.toast .toast-header strong {
    float: left;
    width: auto;
    display: block;
    font-size: 14px;
    color: #363535;
}

.toast .toast-header small {
    float: left;
    font-size: 12px !important;
    font-weight: bold;
    margin: 2px 0 0px 8px;
}

.toast .toast-body {
    clear: both;
    float: left;
    width: 100%;
    padding: 5px 20px 15px;
    font-size: 16px;
    color: rgb(54 53 53 / 80%);
}

.toast-container .toast-deck {
    padding:30px;
}

.toast-container {
    position: relative;
    min-height: 200px;
}

.toast-container .toast-deck {
    position: fixed;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 4;
}

.toast-container .toast-deck .toast {
    z-index: 5;
    width: 450px;
}

/* toasts */// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

/* toasts */

.toast-container {
    position: relative;
    min-height: 0px;
}

.toast {
    -webkit-box-shadow: 0px 0px 22px 0px rgb(54 53 53 / 21%);
    -moz-box-shadow: 0px 0px 22px 0px rgb(54 53 53 / 21%);
    box-shadow: 0px 0px 22px 0px rgb(54 53 53 / 21%);
    background: #ffffff;
    border: none;
    border-radius: 0px;
    float: left;
    width: 100%;
    display: block;
    margin-bottom: 15px;
}

.toast .toast-header {
    float: left;
    width: 100%;
    display: block;
    padding: 13px 20px 10px;
    position: relative;
    padding-right: 50px;
    border: none;
}

.toast .toast-header button {
    position: absolute;
    color: red;
    opacity: 1;
    font-size: 24px;
    margin: 0px !important;
    width: 30px;
    height: 30px;
    text-align: center;
    right: 10px;
    top: 8px;
}

.toast .toast-header button:hover {
    opacity:0.6;
}

.toast .toast-header strong {
    float: left;
    width: auto;
    display: block;
    font-size: 14px;
    color: #363535;
}

.toast .toast-header small {
    float: left;
    font-size: 12px !important;
    font-weight: bold;
    margin: 2px 0 0px 8px;
}

.toast .toast-body {
    clear: both;
    float: left;
    width: 100%;
    padding: 5px 20px 15px;
    font-size: 16px;
    color: rgb(54 53 53 / 80%);
}

.toast-container .toast-deck {
    padding:30px;
}

.toast-container {
    position: relative;
    min-height: 200px;
}

.toast-container .toast-deck {
    position: fixed;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 4;
}

.toast-container .toast-deck .toast {
    z-index: 5;
    width: 450px;
}

/* toasts */


/** Lawrences */
.ui-dialog {
    background-color: white;
    border: 1px solid;
    padding: 10px;
}
